import { Injectable, Inject, forwardRef } from "@angular/core";
import { APIConstants, ForecastNotificationType, Services } from "../application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { INotification, SignalRConnectionInfo, NotificationType } from "./contracts/notification-bar.contracts";
import * as signalR from "@microsoft/signalr";
import { HubConnection } from "@microsoft/signalr";
import { IProcessNotification } from "./contracts/process-notifications.contracts";
import { Store } from "@ngrx/store";
import { IState } from "../../store/reducers";
import { UpdateWriteOperationNotifications } from "../../store/write-operation-notifications/write-operation-notifications.action";
import { UpdateBulkIntEngStatusNotifications } from "../../store/bulk-internal-engagement-status-notifications/bulk-internal-engagement-status-notifications.action";
import { AddNotification, RemoveNotification } from "../../store/notification-subscriptions/notification-subscriptions.action";
import { UserInfoService } from "@fxp/fxpservices";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";
import { SyncEacLog } from "./contracts/sync-eac-log.contract";
import { SyncEACUserAction } from "./contracts/sync-eac-user-action";
import { SyncEACdetailsUI } from "./contracts/sync-eac-details-ui.contract";
import { FcrDemandData } from "./contracts/fcr-demand-data.contract";


@Injectable()
export class DmNotificationService extends DmServiceAbstract {
    public static readonly NEGOTIATE = "pjm.negotiate";
    private static readonly BULK_ENGAGEMENT_STATUS_SUBSCRIBE = "bulkIntEngStatusSubscribe";
    private static readonly RELEASE_AND_ACTIVATE_SUBSCRIBE = "ReleaseAndActivateSubscribe";
    private static readonly WBS_STATUS_CHANGE_SUBSCRIBE = "WbsStatusChangeSubscribe";
    private static readonly FORECAST_NOTIFICATION_SUBSCRIBE = "ForecastNotificationSubscribe";

    private projectServiceBaseUriv1: string;
    private projectServiceFunctionV2BaseUrl: string;
    private projectServiceFunctionV3BaseUrl: string;
    private forecastFunctionBaseUrl: string;
    private forecastFunctionSAPBaseUrl: string;
    private bulkIntEngSubscribeUrl: string;
    private negotiateUrl: string;
    private subscriptionKey: string;
    private hubConnection: HubConnection;

    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.NotificationService);
        this.configManagerService.initialize().then(() => {
            this.initializeConfig();
        });

    }

    /**
     * Start the notification hub
     */
    public startConnection(): void {
        this.negotiateUrl = this.projectServiceFunctionV2BaseUrl + "/v1/notification/negotiate";
        const loggedInUserData = this.fxpUserInfoService.getCurrentUserData();

        const additionalOptions = {
            "x-client-userid": loggedInUserData.alias.toLowerCase()
        };


        this.dataService.getData<SignalRConnectionInfo>(this.negotiateUrl, this.subscriptionKey, DmNotificationService.NEGOTIATE, undefined, undefined, undefined, undefined, undefined, undefined, additionalOptions).then((info: SignalRConnectionInfo) => {
            const options = {
                accessTokenFactory: () => info.accessToken
            };
            this.hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(info.url, options)
                .configureLogging(signalR.LogLevel.Information)
                .build();

            this.hubConnection.start();

            this.hubConnection.onclose(() => {
                this.restartHubConnection();
            });

            this.hubConnection.on("ReleaseAndActivateStatusUpdated", (data: IProcessNotification) => {
                this.store.dispatch(new UpdateWriteOperationNotifications(data));
            });

            this.hubConnection.on("RiskReserve", (data: IProcessNotification) => {
                this.store.dispatch(new UpdateWriteOperationNotifications(data));
            });

            this.hubConnection.on("DateChangeStatusUpdated", (data: IProcessNotification) => {
                this.store.dispatch(new UpdateWriteOperationNotifications(data));
            });

            this.hubConnection.on("BulkCreateStatusUpdated", (data) => {
                this.store.dispatch(new UpdateBulkIntEngStatusNotifications(data));
            });

            this.hubConnection.on("ProjectClosureStatusUpdated", (data) => {
                this.store.dispatch(new UpdateWriteOperationNotifications(data));
            });

            this.hubConnection.on("SyncEACToPlanUpdated", (data) => {
                this.store.dispatch(new UpdateWriteOperationNotifications(data));
            });
        });
    }

    /**
     * Stop the notification hub
     */
    public stopConnection(): void {
        if (this.hubConnection) {
            this.hubConnection.stop();
        }
    }

    /**
     * Initialize the subscription for the Bulk Internal engagement
     */
    public subscribeToBulkInternalEngagementStatus(payload: { GroupName: string }): Promise<any> {
        return this.dataService.putData(this.bulkIntEngSubscribeUrl, this.subscriptionKey, DmNotificationService.BULK_ENGAGEMENT_STATUS_SUBSCRIBE, payload);
    }

    /**
     * Initialize the subscription for a given notification
     */
    public getNotificationStatus(subscriptionId: string, notificationType: NotificationType): Promise<IProcessNotification> {
        const url: string = `${this.projectServiceFunctionV2BaseUrl}/v1/notification/${notificationType}/group/${subscriptionId}`;
        return this.dataService.getData(url, this.subscriptionKey, DmNotificationService.RELEASE_AND_ACTIVATE_SUBSCRIBE);
    }

    /**
    * Initialize the subscription for a given notification
    */
    public getWbsStatusChangeNotificationStatus(subscriptionId: string, notificationType: NotificationType): Promise<IProcessNotification> {
        const url: string = `${this.projectServiceFunctionV3BaseUrl}/v3.0/notificationV3/${notificationType}/group/${subscriptionId}`;
        return this.dataService.getData(url, this.subscriptionKey, DmNotificationService.WBS_STATUS_CHANGE_SUBSCRIBE);
    }

    /**
     * Returns current execution steps for given orchestration
     * @param subscriptionType 
     * @param groupName 
     * @returns 
     */
    public getForecastNotificationSteps(subscriptionType: ForecastNotificationType, groupName: string): Promise<IProcessNotification> {
        const subscriptionTypeString = ForecastNotificationType[subscriptionType];
        const url: string = `${this.forecastFunctionBaseUrl}/api/v3.0/notificationV3/${subscriptionTypeString}/group/${groupName}`;
        return this.dataService.getData(url, this.subscriptionKey, DmNotificationService.FORECAST_NOTIFICATION_SUBSCRIBE);
    }

    /**
     * Gets sync EAC information for passed orchestrationId
     * @param orchestrationId 
     * @returns 
     */
    public getSyncEACData(orchestrationId: string): Promise<SyncEacLog> {
        const url: string = `${this.forecastFunctionBaseUrl}/api/v3.0/SyncEacLog/${orchestrationId}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetSyncEACData);
    }

    /**
     * Posts user confirmation details
     * @param data 
     * @returns 
     */
    public sendEACUserActionDetails(data: SyncEACUserAction): Promise<any> {
        const url: string = `${this.forecastFunctionBaseUrl}/api/v3.0/SendEACUserAction`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.SendEACUserActionDetails, data);
    }

    /**
     * 
     * @param data Initiates sync eac to plan orchestration for passed data
     * @returns 
     */
    public submitEACToPlanRequest(data: SyncEACdetailsUI): Promise<any> {
        const url: string = `${this.forecastFunctionBaseUrl}/api/v3.0/SyncEacToPlan_HttpStart`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.SubmitEACToPlanRequest, data);
    }

    /**
     * Used to get the demand information that is used while creating FCR
     * @param projectId 
     * @returns 
     */
    public getEACDemandDetails(projectId: string): Promise<FcrDemandData[]> {
        const url: string = `${this.forecastFunctionSAPBaseUrl}/api/v3.0/SyncEac/${projectId}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetEACDemandDetails);
    }

    /**
     * Creates the notification subscription entry.
     *
     * @param {NotificationType} notificationType
     * @param {string} userId
     * @param {string} entityId
     * @param {string} orchestrationId
     * @returns {Promise<INotification>}
     * @memberof DmNotificationService
     */
    public createNotificationSubscriptionEntry(notificationType: NotificationType, userId: string, entityId: string, orchestrationId: string, entityDescription?: string): Promise<INotification> {
        const url = `${this.projectServiceBaseUriv1}/user/subscription`;
        const notificationEntry: INotification = {
            userId,
            id: orchestrationId,
            type: notificationType,
            entityId,
            createdBy: userId,
            createdOn: new Date().toISOString()
        };
        if (entityDescription && entityDescription.length > 0) {
            notificationEntry.entityDescription = entityDescription;
        }

        return this.dataService.postData(url, this.subscriptionKey, APIConstants.CreateNotificationSubscriptionEntry, notificationEntry);
    }

    /**
     * Fetch all the notification subscriptions for the given user
     */
    public getAllNotificationSubscriptions(date?: string): Promise<INotification[]> {
        date = date ? `?fromDate=${date}` : "";
        const url = `${this.projectServiceBaseUriv1}/user/subscription${date}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetAllNotificationSubscriptions);
    }

    /**
     * Delete the notification for the given user and remove the same from the store
     */
    public deleteNotificationSubscription(userAlias: string, subscriptionId: string): Promise<void> {
        const url = `${this.projectServiceBaseUriv1}/user/subscription/${subscriptionId}`;
        return this.dataService.deleteData(url, this.subscriptionKey).then(() => {
            this.removeNotificationFromStore(userAlias, subscriptionId);
        });
    }

    /**
     * Add the notification to the notification store
     */
    public addNotificationToStore(userAlias: string, userBpid: string, notificationEntityId: string, NotificationId: string, notificationType: NotificationType, notificationEntityDescription?: string): void {
        const notification: INotification = {
            createdBy: userBpid,
            createdOn: new Date().toISOString(),
            entityId: notificationEntityId,
            id: NotificationId,
            type: notificationType,
            userId: userBpid
        };
        if (notificationEntityDescription && notificationEntityDescription.length > 0) {
            notification.entityDescription = notificationEntityDescription;
        }
        this.store.dispatch(new AddNotification(userAlias, notification));
    }

    /**
     * Remove the notification from the notification store
     */
    public removeNotificationFromStore(userAlias: string, notificationId: string): void {
        this.store.dispatch(new RemoveNotification(userAlias, notificationId));
    }

    private restartHubConnection(): void {
        this.stopConnection();
        this.startConnection();
    }

    private initializeConfig(): void {
        this.projectServiceBaseUriv1 = this.configManagerService.getValue<string>("projectServiceBaseUri") + "v1.0";
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
        this.projectServiceFunctionV2BaseUrl = this.configManagerService.getValue<string>("projectServiceFunctionV2BaseUrl");
        this.bulkIntEngSubscribeUrl = this.projectServiceFunctionV2BaseUrl + "/v1/notification/subscribe/group";
        this.projectServiceFunctionV3BaseUrl = this.configManagerService.getValue<string>("projectServiceFunctionV3BaseUrl");
        this.forecastFunctionBaseUrl = this.configManagerService.getValue<string>("forecastFunctionBaseUrl");
        this.forecastFunctionSAPBaseUrl = this.configManagerService.getValue<string>("forecastFunctionSAPBaseUrl");

    }
}

