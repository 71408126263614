import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const uat: IPartnerAppSettings = {
  flightingAppEnvironment: 'Uat',
  esxpBaseUrl: 'https://servicesuat.microsoft.com/',
  cdnBaseUrl: 'https://grm-ui-uat.azureedge.net/availability',
  serviceEndPoints: [
    {
      clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
      serviceEndPoint: 'https://oneprofileuatapipack.azurefd.net',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint:
        'https://professionalservicesint.microsoft.com/time-core',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://servicesuat.microsoft.com/rmavl',
    },
    {
      clientId: '778329d6-a980-4f3e-bf02-7bc44a384e15',
      serviceEndPoint: 'https://esxp-uat.azurefd.net/grm/rm',
    },
    {
      clientId: '778329d6-a980-4f3e-bf02-7bc44a384e15',
      serviceEndPoint: 'https://servicesuat.microsoft.com',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://emailnotifications-uat-wus.azurewebsites.net',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://maintenancebackgroundfunction-uat-wus.azurewebsites.net',
    },
    {
      clientId: 'c5779b49-c238-45cb-8974-36958d4c7d96',
      serviceEndPoint: 'https://pslakedsdev.azurewebsites.net',
    }
  ],
  OneProfileBaseUrl: 'https://oneprofileuatapipack.azurefd.net',
  TimeCoreBaseUrl: 'https://professionalservicesint.microsoft.com/time-core',
  TimeSubscriptionKey: 'fd723c776fbd432fb12471dcf25d5c10',
  AvailabilityBaseUrl: 'https://servicesuat.microsoft.com/rmavl',
  UpdateAutomatedForecastUrl: 'https://maintenancebackgroundfunction-uat-wus.azurewebsites.net',
  EmailNotificationBaseUrl:
    'https://emailnotifications-uat-wus.azurewebsites.net',
  GrmApiBaseUrl: 'https://esxp-uat.azurefd.net/grm/rm',
  GrmAzureSearchUrl: 'https://servicesuat.microsoft.com',
  PsdlISDForecastBaseUrl :'https://pslakedsdev.azurewebsites.net',
  ProjectDetailsEndUrl: '/api/v1/Search/grmuat/grm-assignmentsearch-uat-index',
  TimeAssetsUrl: 'LMT-AFD-ENDPOINT-UAT-fdd8eehqh6bsfbgk.b02.azurefd.net/assets/',
  RMResourceForecast: {
    NonProjectEventPriority: [35, 22, 16, 13, 33, 21, 37, 11, 4, 17],
    ClarityCode: '',
  },
  RMExperience: {
    batchSize: 5,
    numAvAPIParallelCalls: 4,
    RMPageSize: 10,
    isCosmosEndpoint: true,
  },
};
